<template>
  <div class="wrap" v-loading="loading">
    <div class="info">
      <div class="user">
        <div class="avatar">
          <img v-if="userInfo.avatar" :src="avatar + '/' + userInfo.avatar" alt="">
          <template v-else>
            <img v-if="userInfo.sex==1" src="~@/assets/img/base/male.png" alt="">
            <img v-if="userInfo.sex==2" src="~@/assets/img/base/female.png" alt="">
          </template>
        </div>
        <ul>
          <li><span>姓名：</span>{{userInfo.realname}}</li>
          <li><span>性别：</span>{{userInfo.sex==1?'男':userInfo.sex==2?'女':'未知'}}</li>
          <li><span>专业：</span>{{userInfo.majorName}}</li>
          <li><span>系部：</span>{{userInfo.facultyName}}</li>
          <li><span>班级：</span>{{userInfo.className}}</li>
        </ul>
      </div>
      <div class="nums">
        <div class="item">
          <span class="icon">
            <i class="iconfont icon-15shangzhang"></i>
          </span>
          <div class="text">
            <strong>{{scoreMap.synScore}}</strong>
            <p>综合素质得分</p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <i class="iconfont icon-15shangzhang"></i>
          </span>
          <div class="text">
            <strong>{{scoreMap.maxScore}}</strong>
            <p>班级最高分</p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <i class="iconfont icon-16xiajiang"></i>
          </span>
          <div class="text">
            <strong>{{scoreMap.minScore}}</strong>
            <p>班级最低分</p>
          </div>
        </div>
        <div class="item">
          <span class="icon">
            <i class="iconfont icon-shuju"></i>
          </span>
          <div class="text">
            <strong>{{scoreMap.avgScore}}</strong>
            <p>班级均分</p>
          </div>
        </div>
      </div>
      <div class="b-card table-wrap">
        <div class="b-title">
          <div class="tit">
            <h4>综合素质评价</h4>
          </div>
          <small @click="toDetail('studentEvaluateTotal')"><i class="el-icon-right"></i>更多</small>
        </div>
        <div class="main">
          <ul v-for="(item,index) in evatMilestone" :key="index">
            <li>{{item.label}}</li>
            <li>{{item.ysScore}}</li>
            <li>{{item.bpTotal}}</li>
            <li>{{item.hpTotal}}</li>
            <li>{{item.spTotal}}</li>
            <li>{{item.zpTotal}}</li>
            <li>{{item.paperTotal}}</li>
            <li>{{item.honorScore}}</li>
            <li>{{item.violationScore}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="b-card honour-card">
      <div class="b-title">
        <div class="tit">
          <h4>个人荣誉</h4>
          <span>
            （共
            <strong>{{ (honorMap && honorMap.honorInfo) && honorMap.honorInfo.total}}</strong>项，加分
            <strong>{{(honorMap && honorMap.honorInfo) && honorMap.honorInfo.score}}</strong>分）
          </span>
        </div>
        <small @click="toDetail('studentEvaluateRealistic')"><i class="el-icon-right"></i>更多</small>
      </div>
      <div class="main">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item,index) in honorMap.honorList" :key="index">
            <div class="item">
              <div class="tit">{{item.projectName}}</div>
              <div class="con">
                <p>荣誉类别：<span>{{item.type}}</span></p>
                <p>加分： <span>{{item.score}}</span></p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="b-card record-card">
      <div class="b-title">
        <div class="tit">
          <h4>违规违纪</h4>
          <span>
            （共
            <strong>{{ (violationMap && violationMap.violationInfo) && violationMap.violationInfo.total}}</strong>项，减分
            <strong>{{(violationMap && violationMap.violationInfo) && violationMap.violationInfo.score}}</strong>分）
          </span>
        </div>
        <small  @click="toDetail('studentEvaluateRealistic')"><i class="el-icon-right"></i>更多</small>
      </div>
      <div class="main">
        <div class="item" v-for="(item,index) in violationMap.violationList" :key="index">
          <h4>{{item.projectName}}</h4>
          <strong>-{{item.score}}</strong>
        </div>
      </div>
    </div>
    <div class="b-card line-card">
      <div class="b-title">
        <div class="tit">
          <h4>增值分析</h4>
        </div>
      </div>
      <div style="height:280px" id="line-echart"></div>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="b-card ld-card">
          <div class="b-title">
            <div class="tit">
              <h4>评价维度分析</h4>
            </div>
          </div>
          <div style="height:280px" id="ld-echart"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="b-card bar-card">
          <div class="b-title">
            <div class="tit">
              <h4>评价主体模型</h4>
            </div>
          </div>
          <div style="height:280px" id="bar-echart"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      avatar:process.env.VUE_APP_BASE_API+'/sys/common/static',
      userInfo:{},
      analysisMap:{},
      dimensionMap:{},
      evatMilestone:[],
      honorMap:{},
      mainbodyMap:{},
      scoreMap:{},
      violationMap:{},
      loading:true,
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    getData(){
      let id = JSON.parse(localStorage.getItem('ss-userInfo')).id;
      this.$get('/evas/statistics/getStuPortrait',{ id }).then(res=>{
        console.log(res,'=res')
        this.loading = false;
        let data = JSON.parse(localStorage.getItem('ss-userInfo'));
        this.userInfo = {...data,...res.result.stuInfo}
        this.analysisMap = res.result.analysisMap;
        this.dimensionMap = res.result.dimensionMap;
        this.evatMilestone = res.result.evatMilestone;
        this.honorMap = res.result.honorMap;
        this.mainbodyMap = res.result.mainbodyMap;
        this.scoreMap = res.result.scoreMap;
        this.violationMap = res.result.violationMap;
        this.createLine();
        this.createLd();
        this.createBar();
      })
    },
    toDetail(routerName){
      this.$router.push({
        name:routerName
      })
    },
    createLine(){
      this.$nextTick(()=>{
        let echart = this.$echarts.init(document.getElementById('line-echart'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top:'18%',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          legend: {
            top: '0',
            left: 'center',
            textStyle:{
              color:"#000"
            },
            type:'scroll',
            pageIconColor: '#fff',
            pageTextStyle:{
              color:"#fff"
            },
            data:this.analysisMap.legendData
          },
          xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          },
          yAxis: {
            type: 'value'
          },
          series: this.analysisMap.yAxis
        }
        echart.setOption(option)
      })
    },
    createLd(){
      this.$nextTick(()=>{
        console.log(this.dimensionMap.value,'dfdf')
        let echart = this.$echarts.init(document.getElementById('ld-echart'));
        let option = {
          tooltip: {
            trigger: 'item',
          },
          radar: {
            indicator: this.dimensionMap.name,
            name:{
              formatter:function(value){
                if(value.length > 12){
                  return `${value.slice(0,12)}...`;
                }
                return value;
              },
            }
          },
          series: [
            {
              type: 'radar',
              areaStyle: {},
              data: [
                {
                  name:'评价维度分析',
                  value:this.dimensionMap.value,
                }
              ]
            }
          ]
        };
        echart.setOption(option)
      })
    },
    createBar(){
      this.$nextTick(()=>{
        let echart = this.$echarts.init(document.getElementById('bar-echart'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top:'18%',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: this.mainbodyMap.xAxis
          },
          yAxis: {
            type: 'value'
          },
          series: this.mainbodyMap.series
        }
        echart.setOption(option)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  min-height: 700px;
}
.info{
  background: linear-gradient(#7196fa,#2d5edd);
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid #6187e9;
  display: flex;
  .user{
    width: 218px;
    padding-top: 10px;
    .avatar{
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background: #fff;
      border: 3px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px auto;
      img{
        width: 100%;
        max-width: 100%;
      }
    }
    ul{
      margin-left: 30px;
      li{
        padding: 4px 0;
        font-size: 14px;
        color: #ffff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        span{
          color: rgba(255,255,255,0.5);
        }
      }
    }
  }
  .nums{
    padding: 15px 16px 10px;
    background: #fff;
    border-radius: 6px;
    margin: 0 10px;
    .item{
      display: flex;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      .icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        background: #ccc;
        margin-right: 8px;
        i{
          font-size: 26px;
        }
      }
      .text{
        text-align: center;
        strong{
          font-size: 20px;
        }
        p{
          font-size: 14px;
          color: #354862;
          font-weight: 600;
        }
      }
    }
    .item:nth-child(1),
    .item:nth-child(3){
      .icon{
        background: #fef2e0;
        i{
          color: #f6a41f;
        }
      }
      .text{
        strong{
          color: #ff7500;
        }
      }
    }
    .item:nth-child(2){
      .icon{
        background: #f1f6ff;
        i{
          color: #4a8dfe;
        }
      }
      .text{
        strong{
          color: #6f8ef1;
        }
      }
    }
    .item:nth-child(4){
      .icon{
        background: #e7dbfd;
        i{
          color: #a371f6;
        }
      }
      .text{
        strong{
          color: #7549c1;
        }
      }
    }
    .item+.item{
      border-top: 1px dashed #eceff7;
    }
  }
  .table-wrap{
    flex: 1;
    margin-bottom: 0;
    padding: 15px;
    .b-title .tit h4::before{
      background: #4d53b1;
    }
    .main{
      display: flex;
      margin-top: 10px;
      ul{
        flex: 1;
        border: 1px solid #a7c4eb;
        box-sizing: border-box;
        margin-left: -1px;
        li{
          padding: 5px 0;
          text-align: center;
          font-size: 14px;
          color: #000;
        }
        li:nth-child(1),
        li:nth-child(2){
          background: #dceafd;
        }
        li:nth-child(1){
          font-weight: 600;
          color: #343a52;
          padding-bottom: 5px;
        }
        li:nth-child(2){
          font-weight: 600;
          color: #f17700;
          padding-top: 0;
          border-top: 0;
        }
        li+li{
          border-top: 1px solid #a7c4eb;
        }
      }
    }
  }
}
.honour-card{
  margin-top: 20px;
  .b-title .tit h4::before{
    background: #4d53b1;
  }
  .main{
    margin-top: 20px;
    .el-col:nth-child(1){
      .item .tit{
        background: url(~@/assets/img/base/honour-bg1.png) no-repeat 90% top,linear-gradient(to right, #f44646,#ff827c);
      }
    }
    .el-col:nth-child(2){
      .item .tit{
        background: url(~@/assets/img/base/honour-bg2.png) no-repeat 90% top,linear-gradient(to right, #437bd9,#74a4e6);
      }
    }
    .el-col:nth-child(3){
      .item .tit{
        background: url(~@/assets/img/base/honour-bg3.png) no-repeat 90% top,linear-gradient(to right, #e29502,#fecd7e);
      }
    }
    .el-col:nth-child(4){
      .item .tit{
        background: url(~@/assets/img/base/honour-bg4.png) no-repeat 90% top,linear-gradient(to right, #0ca020,#77e575);
      }
    }
  }
  .item{
    border-radius: 6px;
    overflow: hidden;
    .tit{
      height: 50px;
      text-align: center;
      padding: 0 10px;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      background: #ccc;
      line-height: 50px;
    }
    .con{
      border: 1px solid #e4e4e4;
      border-top: none;
      border-radius: 0 0 6px 6px;
      height: 60px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      p{
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        span{
          color: #f04a01;
        }
      }
      p:nth-child(1){
        width:60%;
        border-right: 1px solid #f04a01;
      }
      p:nth-child(2){
        width:40%;
      }
    }
  }
}
.record-card{
  .b-title .tit h4::before{
    background: #4d53b1;
  }
  .main{
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    .item{
      display: flex;
      align-items: center;
      height: 62px;
      border: 1px dashed #2a5ad8;
      border-radius: 6px;
      margin:20px 10px 0;
      width: calc(100%/5 - 20px);
      padding: 0 20px;
      box-sizing: border-box;
      h4{
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        color: #090909;
      }
      strong{
        color: #4d53b1;
      }
    }
  }
}
.line-card{
  .b-title .tit h4::before{
    background: #5ab522;
  }
}
.ld-card{
  .b-title .tit h4::before{
    background: #e77a18;
  }
}
.bar-card{
  .b-title .tit h4::before{
    background: #2b5cda;
  }
}
.b-card{
  background: #fff;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 20px;
}
.b-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tit{
    h4{
      display: inline-block;
      font-size: 22px;
      color: #090909;
      padding-left: 15px;
      position: relative;
      &::before{
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 30px;
        background: #cccccc;
        position: absolute;
        height: 80%;
        top: 5px;
        left: 0;
      }
    }
    span{
      font-size: 16px;
      color: #838383;
      strong{
        color: #20279c;
      }
    }
  }
  small{
    font-size: 20px;
    color: #5f5f5f;
    cursor: pointer;
  }
}
</style>
